/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-05",
    versionChannel: "nightly",
    buildDate: "2024-09-05T00:05:37.479Z",
    commitHash: "f538f67a17a4d26db78528e2276a2c7b8d0c729c",
};
